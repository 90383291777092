// import i18n from '@/i18n/index'

export default {
  tableDataItem: [{
    prop: 'name',
    label: '',
    Object: 'value',
    width: '150'
  }, {
    prop: 'poi_ids',
    label: '',
    Object: 'value',
    width: '150'
  }, {
    prop: 'coupon_type',
    Object: 'value',
    label: '',
    width: '150'
  }, {
    prop: 'discount',
    label: '',
    Object: 'value',
    width: '120'
  }, {
    prop: 'target_amount',
    label: '',
    Object: 'value',
    width: '120'
  }, {
    prop: 'validity',
    label: '',
    Object: 'value',
    width: '120'
  }, {
    prop: 'price',
    label: '',
    Object: 'value',
    width: '120'
  }, {
    prop: 'shelves',
    label: '',
    Object: 'value',
    width: '120'
  }]
}
